import React from "react"

import { InfoBody, InfoContent } from "./faq"

export default function AcceptableUsePolicy() {
  return (
    <InfoBody title="Acceptable Use Policy">
      <InfoContent>
        Acceptable Use Policy - 03.18.20
        <br />
        https://www.mountproxies.com/acceptableusepolicy
        <br />
        <br />
        This Acceptable Use Policy sets forth conditions of use of the services
        of MountProxies. Services related to MountProxies are found at
        https://www.mountproxies.com. This Acceptable Use Policy is intended
        only to act as a guideline to the service of MountProxies (“Service” or
        “Services”).
        <br />
        <br />
        The following list sets forth violations of this Acceptable Use Policy,
        but other acts or uses, not listed herein, may also constitute
        violations of this Acceptable Use Policy in connection with a Customer’s
        use of MountProxies’s Services:
        <br />
        <br />
        1. Copyright and Other Proprietary Right Infringement: Customer agrees
        not to infringe on copyrighted, patented, other intellectual property
        rights, and proprietary material of third parties, under all relevant
        laws, foreign or domestic, federal, state, or local, including, without
        limitation, the Copyright Act, as amended, and the Digital Millennium
        Copyright Act.
        <br />
        <br />
        2. Spamming: Customer agrees that it will not engage in any spamming
        activities, including, without limitation, the sending, re-sending,
        transmitting, or the like, of bulk materials by means of MountProxies’s
        Services, whether directly or indirectly. Such sending, re-sending,
        transmitting or the like is prohibited by any means of MountProxies’s
        Services, including, without limitation, through email, blog comment,
        social network message, and forum spam.
        <br />
        <br />
        3. Illegal Activities: Customer agrees not to engage in illegal
        activities. This includes, but is not limited to: death threats,
        terroristic threats, threats of harm to any individual or organization,
        multi-level marketing schemes, “Ponzi schemes”, invasion of privacy,
        credit card fraud, racketeering, identity theft, defamation, slander,
        and other illegal activities.
        <br />
        <br />
        4. Child Pornography: Customer agrees not to, without limitation, view,
        promote, or engage in any activity relating to child pornography, and
        violation of laws relating thereto, which includes without limitation,
        Prosecutorial Remedies and Other Tools to end the Exploitation of
        Children Today Act, the Child Online Protection Act, Communications
        Decency Act of 1996, and Children’s Internet Protection Act, all as
        amended.
        <br />
        <br />
        5. Threats and Harassment: Customer agrees not to make threats to or
        harass any individual, organization or business.
        <br />
        <br />
        6. Fraudulent Activities: Customer agrees that it will not engage in
        fraudulent activities.
        <br />
        <br />
        7. Denial of Service: Customer agrees that it will not engage in the
        origination of or the control of denial of service attacks, distributed
        denial of service attacks, or engage in similar activities.
        <br />
        <br />
        8. Distribution of Malware: Customer agrees not to engage in activities,
        without limitation, the distribution, fabrication, or use of malware
        including, virus software, root kits, password crackers, spyware,
        adware, key stroke capture programs and other programs normally used in
        malicious activity.
        <br />
        <br />
        9. Hacking: Customer agrees not to engage in gaining or attempting to
        gain unauthorized access to any network or system, including, without
        limitation, computer, telephone, telefax, and any other system of data
        storage.
        <br />
        <br />
        10. Phishing: Customer agrees not to engage in any activity associated
        with phishing or systems designed to collect personal information,
        including, without limitation, names, account numbers, usernames,
        passwords, and other identifying information.
        <br />
        <br />
        11. Fraudulent Financial Schemes or Ponzi Schemes: Customer agrees not
        to engage in the promotion, offer, or sale of interests in illegal
        financial or Ponzi schemes.
        <br />
        <br />
        12. Stalking: Customer agrees not to engage in any stalking of either
        person, organization, or otherwise.
        <br />
        <br />
        13. Bullying: Customer agrees not to engage in bullying of any person,
        organization, or otherwise.
        <br />
        <br />
        14. Derogatory or Defamatory Remarks or Conduct: Customer agrees not to
        engage in any conduct or make remarks which are derogatory, demeaning,
        malicious, hateful, defamatory, or abusive.
        <br />
        <br />
        15. Impersonation: Customer agrees not to impersonate any other person
        or take on any other person’s identity, whether living or deceased, or
        impersonate an entity or take on the identity of any entity, whether
        legal or informal.
        <br />
        <br />
        16. Violations Alleged by Third Parties and Determination of such
        Violations of this Acceptable Use Policy or Other Violations:
        MountProxies accepts reports from third parties of alleged violations of
        this Acceptable Use Policy or concerning other violations. MountProxies
        will review all third party reports and may take appropriate actions, at
        is sole discretion, relative to Customer.
        <br />
        <br />
        17. Excessive Connections: Customer agrees that the MountProxies, at its
        sole discretion can limit the number of simultaneous connections as well
        as the number of connections per section, as necessary to ensure stable
        service for all users. Such corrective action may include disconnection
        or discontinuance of any and all Services, or termination of this
        Agreement, which actions may be taken is in MountProxies’s sole and
        absolute discretion. If MountProxies takes any corrective action under
        this section, Customer shall not be entitled to a refund of any fees
        paid in advance prior to such action.
        <br />
        <br />
        Upon a detecting a breach of this Acceptable Use Policy, MountProxies
        may, at its sole discretion, immediately terminate MountProxies’s
        services to Customer, without notice, and as stated in MountProxies’s
        Terms of Service found at https://www.mountproxies.com/termsofservice,
        without refund or credit, and possibly with abuse processing fees as
        outlined in the Terms of Service. Customer acknowledges, understands and
        agrees that MountProxies is not in a position to ascertain whether an
        alleged violation is real or valid, but must make a decision based on
        information received from such third parties. Customer agrees that
        MountProxies’s decision will be final, determinative, and binding upon
        Customer. MountProxies, at its sole discretion, may immediately terminate
        MountProxies’s services to Customer, without notice, and as stated in
        MountProxies’s Terms of Service, without refund or credit.
        <br />
        <br />
        Customer agrees to hold MountProxies harmless, for relying on the third
        party’s report and for any action taken in response to such report.
        Customer further agrees to defend and indemnify MountProxies in the event
        a claim is brought against MountProxies or an award, judgment or the like
        is issued against MountProxies resulting from Customer’s use of the
        Services, all as stated in the Terms of Service.
        <br />
        <br />
        None of the rights of MountProxies under this paragraph “17″ shall be
        limited in any way by the paragraph below titled: Disclosure pursuant to
        Subpoena or by anything else in this Acceptable Use Policy or Terms of
        Service.
        <br />
        <br />
        18. Violation of Laws: Customer agrees not to engage in any activity
        which is in violation of civil or criminal law, foreign or domestic,
        whether national, state or local.
        <br />
        <br />
        19. Attempted Access to Certain Websites: Customer is informed and
        agrees that it has no access to certain websites. No attempt shall be
        made to access such websites via MountProxies’s services.
        <br />
        <br />
        20. Use of Certain Software with MountProxies’s Services is Prohibited:
        Customer is informed and agrees that it is prohibited from using certain
        software with MountProxies’s Services.
        <br />
        <br />
        The term “Customer” or “Customer’s” used in this Acceptable Use Policy
        means the person or legal identity specified under the heading “User” in
        the Terms of Service (“Terms of Service”). Without limiting the
        non-assignment provision under the heading “Assignment” in the Terms of
        Service, that would include Customer’s purported assignees, licensees,
        or otherwise, and those who gain unauthorized access to use
        MountProxies’s Services via Customer.
        <br />
        <br />
        Method of Resolution for Alleged Violations under this Acceptable Use
        Policy: Upon discovery by MountProxies of an alleged violation of this
        Acceptable Use Policy, Customer will be notified and given a designated
        time in which to respond to the alleged violation. If Customer fails to
        respond within the time provided, MountProxies may, at its sole
        discretion, immediately terminate MountProxies’s services to Customer,
        without notice, and as stated in MountProxies’s Terms of Service found at
        https://mountproxies.com/termsofservice, without refund or credit. Upon
        receipt of Customer’s response, if MountProxies finds a violation of this
        Acceptable Use Policy, MountProxies, at its sole discretion, may
        immediately terminate MountProxies’s services to Customer, without
        notice, and as stated in MountProxies’s Terms of Service, without refund
        or credit.
        <br />
        <br />
        Security of Customer Information: Customer understands and agrees that
        MountProxies cannot guarantee, assure, or promise that information or
        data of Customer held by MountProxies, transmitted during use via
        MountProxies’s servers (including, without limitation, any and all
        transmissions to and from Customer via MountProxies’s servers) or via
        Customer’s use of Services, will not be compromised by unauthorized
        access. Without limiting any right of MountProxies under the heading
        “Indemnification” found in the Terms of Service, Customer holds
        MountProxies harmless in the event of any breach of security to
        Customer’s data or information as described in this paragraph.
        <br />
        <br />
        Monitoring of Customer’s Use of Services: At its sole discretion,
        MountProxies may monitor Customer’s use of MountProxies’s Services. Such
        monitoring includes, without limitation, the following: the result of
        notice by third parties of a possible violation of this Acceptable Use
        Policy; to protect or enforce MountProxies’s rights under the Terms of
        Service, this Acceptable Use Policy, or the Website Information.
        “Website Information” means information posted by MountProxies on its
        website at https://mountproxies.com.
        <br />
        <br />
        Disclosure pursuant to Subpoena: MountProxies may be required by law to
        provide Customer’s information pursuant to subpoena issued by a court,
        governmental entity or otherwise. Information shall be disclosed as
        directed pursuant to subpoena. MountProxies shall release information
        described in the subpoena. If permitted by law, MountProxies will
        endeavor, but is not obligated, to notify Customer that it has received
        a subpoena for records pertaining to Customer.
        <br />
        <br />
        Data Collection and Retention: MountProxies collects and maintains an
        informational log, including, without limitation, information relating
        to Customer’s uses of and websites visited by Customer via MountProxies’s
        Services, and payment by Customer and related information. Such logs are
        maintained for approximately 60 days, and longer if necessary, at the
        sole discretion of MountProxies.
        <br />
        <br />
        Decline of Acceptance of New Customer or Renewal of MountProxies’s
        Services: MountProxies retains the right, at its sole discretion, to
        decline acceptance of a potential customer, or refuse to supply
        additional Services to or renew Services with any Customer.
        <br />
        <br />
        Terms of Service: The Terms of Service are incorporated by reference and
        made a part hereof.
        <br />
        <br />
        Changes to Acceptable Use Policy: Customer agrees that MountProxies, at
        its sole discretion, may change the terms of this Acceptable Use Policy
        without notice and that Customer shall keep itself informed of
        MountProxies’s Acceptable Use Policy for any and all changes by reviewing
        it regularly on MountProxies’s website at:
        https://mountproxies.com/acceptableusepolicy. Customer’s continued use of
        the Services following such changes constitutes consent and acceptance
        to such changes.
        <br />
        <br />
        Breach: Any breach of this Acceptable Use Policy is a breach of the
        Terms of Service between MountProxies and Customer, and subject to
        immediate termination, without notice, at MountProxies’s sole discretion.
        <br />
        <br />
        Severability: In the event any term or provision in this Acceptable Use
        Policy is found by a tribunal of competent jurisdiction to be invalid,
        the remainder of this Acceptable Use Policy shall remain in full force
        and effect.
      </InfoContent>
    </InfoBody>
  )
}
